@tailwind base;
@tailwind components;
@tailwind utilities;


/* html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(assets/images/background.png) no-repeat center center;
  background-size: cover;
  height: 100%;
  width: 100%;
}

html {
  height: 100%;
  background-color: #282c34;
}
body {
  min-height: 100%;
  color: #fff;
}

h1 {
  font-size: 20px;
} */

html {
  height: 100%;
  background-color: #282c34;
}

body {
  background: url(assets/images/background.png) no-repeat center center;
  background-size: cover;
  min-height: 100%;
}

.bg-opacity-70 {
  background-color: rgba(77, 89, 107, 0.7);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  outline: none;
}

.button-primary {
  /* position: absolute;
  bottom: 0;
  left: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
}

.container {
  position: relative;
  height: 100vh;
}

.img-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 160px;
  margin: 0 auto;
}

.formwm {
  position: relative;
  height: calc(100% - 35%);
}